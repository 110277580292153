<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="查看全部网点" />
    <!-- <input type="date"> -->
    <div style="padding:6px 16px 0px;background-color:#fff;margin-top:17px">
      <div class="tit">当天新增维修工单量：{{gdl}}</div>
      <div class="tit">当天新增维修设备台数：{{sbts}}</div>

    </div>
    <van-cell title="选择日期" :value="date" @click="show = true" is-link />
    <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" />
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="12"><span class="listTitle">网点</span></van-col>
        <van-col span="5" @click="insFn"><span class="listTitle">工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span></van-col>
        <van-col span="5" @click="repairFn"><span class="listTitle">设备台数
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span></van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="12">
                <div class="listTitle">{{item.name}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.orderct}}</div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.machinect}}</div>
                </div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="2"></van-col>
              <van-col span="12">
                <div class="listTitle">{{item.afterNumber}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.ztazgdl>0" class="upFloat"><img style="width:12px;height:12px" src="../assets/icon/shangsheng.png" alt="">{{item.ztazgdl}}%</div>
                  <div v-if="item.ztazgdl<0" class="downFloat"><img style="width:12px;height:12px" src="../assets/icon/down.png" alt="">{{-item.ztazgdl}}%</div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.ztsbs>0" class="upFloat"><img style="width:12px;height:12px" src="../assets/icon/shangsheng.png" alt="">{{item.ztsbs}}%</div>
                  <div v-if="item.ztsbs<0" class="downFloat"><img style="width:12px;height:12px" src="../assets/icon/down.png" alt="">{{-item.ztsbs}}%</div>
                </div>
              </van-col>
            </van-row>
          </van-cell>
        </van-list>
      </van-pull-refresh>

    </div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      list: [],
      gdl: 0,
      sbts: 0,
      show: false,
      minDate: new Date(2020, 1, 1),
      date: "",
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
    };
  },
  created() {
    let val = new Date().getTime() - 24 * 60 * 60 * 1000;
    let time = new Date(val);
    this.date = this.formatDate(time);
  },
  methods: {
    //维修筛选
    repairFn() {
      this.isrepair = !this.isrepair;
      if (this.isrepair) {
        this.list.sort((a, b) => {
          return b.machinect - a.machinect;
        }); /* 从大到小 */
      } else {
        this.list.sort((a, b) => {
          return a.machinect - b.machinect;
        }); /* 从小到大 */
      }
      // this.isrepair = false;
    },
    //安装筛选
    insFn() {
      this.isIns = !this.isIns;
      if (this.isIns) {
        this.list.sort((a, b) => {
          return b.orderct - a.orderct;
        }); /* 从大到小 */
      } else {
        this.list.sort((a, b) => {
          return a.orderct - b.orderct;
        }); /* 从小到大 */
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      this.getList();
    },
    //获取数据
    getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "111111",
        type: "家用",
        startDate: this.date,
        endDate: this.date,
        timeConditionType: "day",
        sumType: 1,
        sumMode: "repair.neworder",
      };
      this.postRequest("/wechat-analysis/summarys", params).then((res) => {
        // console.log(res);
        // let dtxzwxgdl = 0;
        // let dtxzwxsbts = 0;
        // for (let i = 0; i < res.data.length; i++) {
        //   if (res.data[i].btype === "维修") {
        //     dtxzwxgdl = dtxzwxgdl + res.data[i].amount;
        //     dtxzwxsbts = dtxzwxsbts + res.data[i].ctorder;
        //   }
        // }
        // this.list = res.data;
        // this.gdl = dtxzwxgdl;
        // this.sbts = dtxzwxsbts;
        // if (this.refreshing) {
        //   this.list = [];
        //   this.refreshing = false;
        // }
        // if (res.data.length === 0) {
        //   this.list = [];
        //   this.finished = true;
        // }
        // this.list.push(...res.data);
        // this.loading = false;
        // if (this.list.length >= res.data.length) {
        //   this.finished = true;
        // }
        // let ctorder = 0;
        // let arr = res.data.filter((v) => {
        //   if (v.btype === "维修") {
        //     ctorder = ctorder + v.ctorder;
        //   }
        //   return v.btype === "维修";
        // });

        let ctorder = 0;
        let num = 0; //设备台数
        res.data.forEach((v) => {
          ctorder = ctorder + v.orderct;
          num = num + v.machinect;
          let ztazgdl = 0;
          let ztsbs = 0;
          if (v.yorder && v.orderct) {
            ztazgdl =
              (((v.orderct - v.yorder) / v.yorder) * 100).toFixed(2) * 1;
          }
          if (v.machinect && v.ymachine) {
            ztsbs =
              (((v.machinect - v.ymachine) / v.ymachine) * 100).toFixed(2) * 1;
          }
          v.ztazgdl = ztazgdl;
          v.ztsbs = ztsbs;
        });
        this.gdl = ctorder;
        this.sbts = num;
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        if (res.data.length === 0) {
          this.list = [];
          this.finished = true;
        }
        this.list = res.data;
        this.isIns = false;
        this.insFn();
        this.loading = false;
        if (this.list.length >= res.data.length) {
          this.finished = true;
        }
      });
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      this.getList();
    },
    //日期格式化
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)
      }/${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
.tit {
  font-weight: 700;
  font-size: 14px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>